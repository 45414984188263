import { useRouter } from 'next/router';
import { authTheme } from '@/layouts/themes';
import { Container, Box, Grid, CssBaseline, Button } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import { useAuth } from '@/hooks/auth'
import { useKeycloak } from '@react-keycloak/ssr'
import { useEffect, useState } from 'react'

export default function PreLoginScreen() {
    const router = useRouter()

    const { login } = useAuth({
        middleware: 'guest',
        redirectIfAuthenticated: '/dashboard',
    })

    const [errors, setErrors] = useState([])
    const [status, setStatus] = useState(null)
    const [keyCloakLogging, setKeyCloakLogging] = useState(null)

    const {
        keycloak, initialized
    } = useKeycloak();

    useEffect(() => {
        console.log("v: 0.0.1")
    }, []);

    useEffect(() => {
        keycloak
            .init({ checkLoginIframe: false })
            .then((authenticated) => {
                if (authenticated) {
                    setKeyCloakLogging(0)
                    const kcToken = keycloak.token;
                    console.log("TOKEN: " + keycloak.token)

                    login({ setErrors, setStatus, kcToken }).then(
                        () => {
                            setKeyCloakLogging(1)
                        }
                    )
                }
            })
    }, [keycloak.token])
    

    if (keyCloakLogging == 1) {
        return <></>;
    }

    return (
        <>
            <ThemeProvider theme={authTheme}>
                <Container sx={{
                    marginTop: 30,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: "center"
                }}
                    component="main" maxWidth="xs">
                    <CssBaseline />

                    <Box sx={{ marginBottom: '20%' }}>
                        <img style={{ width: '80%' }} src={process.env.NEXT_PUBLIC_RELATIVE_PATH + '/imgs/faes-logo-white.png'} />
                    </Box>

                    <Grid container sx={{ width: 600, backgroundColor: 'white' }}>
                        <Grid item xs={12} style={{ backgroundColor: '#004595', padding: '20px', borderRadius: '0.25rem' }}>
                            <span style={{ color: 'white' }}>Bienvenido/a al Vademécum farmacéutico de Faes Farma</span>
                        </Grid>
                        <Grid item xs={12} style={{ padding: '20px' }} align="center">
                            <h3>¿Qué usuario eres?</h3>
                        </Grid>

                        <Grid item xs={12} style={{ padding: '20px' }}>
                            <Grid container spacing={2}>
                                <Grid item xs={6}>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        onClick={() => keycloak.login()}
                                        style={{ width: '100%' }}>
                                        Delegado
                                    </Button>
                                </Grid>
                                <Grid item xs={6}>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        onClick={() => router.push('/auth/login')}
                                        style={{ width: '100%' }}>
                                        Farmacéutico
                                    </Button>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Container>
            </ThemeProvider>
        </>
    )
}
