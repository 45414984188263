import { createTheme } from "@mui/material/styles";

export const authTheme = createTheme({
    components: {
        MuiCssBaseline: {
            styleOverrides: {
                body: {
                    backgroundImage: 'url("'+ process.env.NEXT_PUBLIC_RELATIVE_PATH+'/imgs/prelogin-wallpaper.jpg")',
                    backgroundSize: '2388px 1668px',
                }
            }
        }
    }
});

export const appTheme = createTheme({
    components: {
        MuiCssBaseline: {
            styleOverrides: {
                a: {
                    textDecoration: 'none',
                    color: '#004595',
                },
                body: {
                    fontFamily: 'Nunito'
                }
            }
        }
    }
});
